<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>巡检管理</a-breadcrumb-item>
            <a-breadcrumb-item>路线点位</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div
          v-if="!visible"
          class="flow-d-row"
          style="float: right; align-items: center"
        >
          <a-button type="primary" @click="showDrawer"> 新增路线 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['state'].value"
                  v-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 173.5px"
                >
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option value="0"> 启用 </a-select-option>
                  <a-select-option value="1"> 禁用 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content style="background: #fff; padding: 24px; margin: 0">
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="linelist"
            :rowClassName="rowClassName"
          >
            <span slot="status" slot-scope="text, record">
              <a-switch
                :checked="text == 0 ? true : false"
                default-checked
                @change="onchangestate(record)"
              />
            </span>
            <span style="font-family: '黑体'" slot="code" slot-scope="text"
              >{{ text }}
            </span>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">编辑</a>&nbsp;&nbsp;
              <a-popconfirm
                title="你确认要删除该条路线么?"
                ok-text="是"
                cancel-text="否"
                @confirm="onDetele(record)"
                @cancel="cancel"
              >
                <a>删除</a>
              </a-popconfirm>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        style="background: #fff; padding: 24px; margin: 0; min-width: 1330px"
      >
        <a-form-model
          ref="ruleForm"
          style="margin-bottom: 30px"
          class="flow-d-row"
          :rules="rules"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="巡检点位编号">
            <a-input
              disabled="true"
              v-model="form.code"
              placeholder="系统自动生成"
            />
          </a-form-model-item>
          <a-form-model-item ref="name" prop="name" label="巡检路线名称">
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.name"
            />
          </a-form-model-item>
          <a-form-model-item
            ref="duration"
            prop="duration"
            label="巡检路线时长(分钟)"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.duration"
            />
          </a-form-model-item>
        </a-form-model>
        <div
          style="
            font-size: 18px;
            font-weight: bold;
            margin: 20px 0;
            border-left: 3px solid #0091ff;
            color: #000;
            padding-left: 10px;
          "
        >
          巡检点位信息
        </div>
        <a-button type="primary" @click="Addsite"> 添加巡查点位 </a-button>
        <a-table
          :row-selection="rowSelection"
          rowKey="id"
          :pagination="false"
          childrenColumnName="child"
          :columns="columns"
          :data-source="form.sites"
          :rowClassName="rowClassName"
        >
          <span style="font-family: '黑体'" slot="code" slot-scope="text"
            >{{ text }}
          </span>
          <a slot="qr" slot-scope="text">
            <a-popover placement="left" trigger="click">
              <template slot="content">
                <span style="width: 90px; height: 90px">
                  <img
                    style="width: 90px; height: 90px"
                    :src="'api/sccn/patrol/site/qr/' + text"
                    alt=""
                  />
                </span>
              </template>
              <span> 点击查看二维码 </span>
            </a-popover>
          </a>
          <template slot="action" slot-scope="text, record">
            <a-popconfirm
              title="你确认要删除该条路线么?"
              ok-text="是"
              cancel-text="否"
              @confirm="onDetelesit(record.id)"
              @cancel="cancel"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </a-table>
        <a-pagination
          v-model="sitparams.current"
          :page-size="sitparams.pageSize"
          show-quick-jumper
          :default-current="1"
          :total="sittotal"
          @change="onpagesizet"
        />
        <a-modal
          :title="title"
          :visible="movisible"
          width="800px"
          @ok="onresave"
          @cancel="onClosemod"
        >
          <div style="overflow: auto">
            <div style="height: 90px" class="flow-d-row">
              <a-form-item style="margin-bottom: 0" label="楼栋">
                <a-select
                  style="width: 173.5px"
                  placeholder="请选择楼栋"
                  @change="onbuildtop"
                  v-model="mositparams['cps.building_id'].value"
                >
                  <a-select-option v-for="item in buildlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item style="margin: 0 10px" label="楼栋">
                <a-select
                  style="width: 173.5px"
                  placeholder="请选择楼栋"
                  v-model="mositparams['cps.floor_id'].value"
                >
                  <a-select-option v-for="item in floorlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-button
                style="margin-top: 43px"
                type="primary"
                @click="sithandleSearch"
              >
                搜索
              </a-button>
            </div>
            <div
              style="
                border: 1px solid #d9d9d9;
                border-bottom: none;
                width: 100%;
                height: 95px;
              "
            >
              <p>已选点位:</p>
              <div class="flow-d-row">
                <span
                  v-for="(item, index) in selectedData"
                  :key="item.id"
                  class="check-box-ro"
                  >{{ item.name
                  }}<a-icon @click="ondeletesmall(index)" type="close"
                /></span>
              </div>
            </div>
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :selectedRowKeys="selectedRowKey"
              :pagination="false"
              childrenColumnName="child"
              :columns="mositcolumns"
              :data-source="siteslist"
              :rowClassName="rowClassName"
            >
              <span style="font-family: '黑体'" slot="code" slot-scope="text"
                >{{ text }}
              </span>
            </a-table>
            <a-pagination
              v-model="mositparams.current"
              :page-size="mositparams.pageSize"
              show-quick-jumper
              :default-current="1"
              :total="mosittotal"
              @change="onpagesizetmo"
            />
          </div>
        </a-modal>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '路线编号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '路线名称',
    dataIndex: 'name',
  },
  {
    title: '路线时长',
    dataIndex: 'duration',
  },
  {
    title: '点位数量',
    dataIndex: 'siteNum',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const mositcolumns = [
  {
    title: '楼栋',
    dataIndex: 'building.name',
  },
  {
    title: '楼层',
    dataIndex: 'floor.name',
  },
  {
    title: '点位编号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '点位名称',
    dataIndex: 'name',
  },
  {
    title: '安装位置',
    dataIndex: 'place',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
// import moment from "moment";
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      //   sitcolumns,
      mositcolumns,
      visible: false,
      movisible: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 20 },
      title: '',
      textlist: ['路线名称', '状态'],
      sittextlist: ['楼栋', '楼层'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      value: '',
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      sitparams: {
        current: 1,
        pageSize: 10,
      },
      mositparams: {
        current: 1,
        pageSize: 10,
        'cps.building_id': {
          value: undefined,
          op: '=',
        },
        'cps.floor_id': {
          value: undefined,
          op: '=',
        },
      },
      params: {
        current: 1,
        pageSize: 10,
        name: {
          value: '',
          op: '%like%',
        },
        state: {
          value: undefined,
          op: '=',
        },
      },
      total: 1,
      sittotal: 1,
      mosittotal: 1,

      selectedRows: [],
      selectedRowKey: [],
      selectedData: [],

      buildlist: [],
      floorlist: [],
      Id: '',
      patrollist: [],
      linelist: [],
      siteslist: [],
      sites: [],
      form: {
        id: '',
        projectId: '',
        name: '',
        code: undefined,
        duration: '',
        state: '',
        sites: [],
        siteIds: [],
      },
      e: '',
      rules: {
        duration: [
          {
            required: true,
            message: '巡检路线时长不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '巡检路线名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['name'].value = values.路线名称
      })
      this.getpatrol()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['state'].value = undefined
    },
    sithandleSearch() {
      this.mositparams.current = 1

      this.getpatrolsitelist(
        this.mositparams['cps.building_id'].value,
        this.mositparams['cps.floor_id'].value
      )
    },
    sithandleReset() {
      this.fom.resetFields()
      this.mositparams['cps.building_id'].value = undefined
      this.mositparams['cps.floor_id'].value = undefined
    },
    toggle() {
      this.expand = !this.expand
    },

    onClose() {
      this.visible = false
      this.selectedRowKey = []
      this.selectedRows = []
      this.form = {
        id: '',
        projectId: '',
        name: '',
        code: undefined,
        duration: '',
        state: '',
        sites: [],
        siteIds: [],
      }
    },
    //模态框操作
    onresave() {
      this.form.sites = this.selectedData
      this.form.siteIds = this.selectedRowKey
      this.sittotal = this.form.sites.length
      this.movisible = false
    },
    onClosemod() {
      this.movisible = false
      if (this.form.id != '') {
        this.form.sites = this.sites
      }
    },
    Addsite() {
      this.getpatrolsitelist()
      this.movisible = true
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log('submit!', this.form)
          if (this.form.id == '') {
            this.Addpatrolline()
          } else {
            this.putpatrolline()
          }
        } else {
          return false
        }
      })
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.getpatrollinestate(e.id, this.form.state)
    },
    // 删除
    onDetele(e) {
      this.deletepatrolline([e.id])
    },
    async deletepatrolline(id) {
      try {
        const res = await http.deletepatrolline(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.title = '编辑'
      this.visible = true
      this.getpatrollineId(item.id)
    },
    ondeletesmall(e) {
      this.selectedRowKey.splice(e, 1)
      this.selectedData.splice(e, 1)
    },
    onpagesize(e) {
      this.params.current = e
      this.getPaymentInfo()
    },
    showDrawer() {
      this.visible = true
      //   console.log(this.form.projectId);
      this.buildingid(this.form.projectId)
      this.title = '新增'
      this.$store.commit('selectKeys')
    },
    onbuildtop(e) {
      this.floorid(e)
    },
    onDetelesit(e) {
      this.form.sites.splice(this.selectedRowKey.indexOf(e), 1)
      this.form.siteIds.splice(this.selectedRowKey.indexOf(e), 1)
      //   console.log(this.form.siteIds);
    },
    async qepatrol(id) {
      try {
        const res = await http.qepatrol(id)
        const { success, data } = res.data
        if (success) {
          console.log(data)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getpatrolsitelist(buildingId, floorId) {
      try {
        const res = await http.getpatrolsitelist(buildingId, floorId)
        const { success, data } = res.data
        if (success) {
          this.siteslist = data.sites
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },

    //查
    async getpatrolline() {
      try {
        const res = await http.getpatrolline(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.linelist = data.lines.records
          this.total = data.lines.total
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //新增
    async Addpatrolline() {
      try {
        const res = await http.Addpatrolline(this.form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //修改
    async putpatrolline() {
      try {
        const res = await http.putpatrolline(this.form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getpatrollineId(id) {
      try {
        const res = await http.getpatrollineId(id)
        const { success, data } = res.data
        if (success) {
          this.form.siteIds = []
          this.form.name = data.site.name
          this.form.code = data.site.code
          this.form.duration = data.site.duration
          this.form.sites = data.site.sites
          data.site.sites.forEach(item => {
            this.form.siteIds.push(item.id)
          })
          this.selectedRowKey = this.form.siteIds
          this.selectedData = this.form.sites
          this.sites = this.form.sites
          this.sittotal = this.form.sites.length
          this.buildingid(data.site.projectId)
          //   console.log(this.selectedRowKey);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //状态
    async getpatrollinestate(id, state) {
      try {
        const res = await http.getpatrollinestate(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          this.buildlist = data.buildings
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onpagesizet(e) {
      this.sitparams.current = e
    },
    onpagesizetmo(e) {
      this.mositparams.current = e
      this.getpatrol()
    },
    timer() {
      return setTimeout(() => {
        this.getpatrolline()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    MergeArray(arr1, arr2) {
      var _arr = []
      for (var i = 0; i < arr1.length; i++) {
        _arr.push(arr1[i])
      }
      for (var x = 0; x < arr2.length; x++) {
        var flag = true
        for (var j = 0; j < arr1.length; j++) {
          if (arr2[x].id === arr1[j].id) {
            flag = false
            break
          }
        }
        if (flag) {
          _arr.push(arr2[x])
        }
      }
      _arr = this.spliceArray(_arr, this.selectedRowKey)
      return _arr
    },
    // 删除未选择数据
    spliceArray(arr1, arr2) {
      var _arr = []
      for (var x = 0; x < arr1.length; x++) {
        for (var j = 0; j < arr2.length; j++) {
          if (arr1[x].id === arr2[j]) {
            _arr.push(arr1[x])
          }
        }
      }
      return _arr
    },
  },
  mounted() {
    this.getpatrolline()
    let users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    this.buildingid(this.form.projectId)
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
    // this.callback();
    // this.getsts();
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          if ((this.form.id != '') & (this.form.sites.length > 0)) {
            this.form.sites.forEach(it => {
              selectedRows.push(it)
              selectedRowKeys.push(it.id)
            })
            this.form.sites = []
            this.form.siteIds = []
          }
          this.selectedRowKey = selectedRowKeys
          this.selectedRows = selectedRows
          if (this.selectedData.length > 0) {
            this.selectedData = this.MergeArray(this.selectedData, selectedRows)
          } else {
            this.selectedData = selectedRows
          }
        },
        // onSelect: (record, selected) => {
        //   if (selected == true) {
        //     this.selectedRowKey.push(record.id);
        //     this.selectedRows.push(record);
        //   } else {
        //     this.selectedRows.splice(this.selectedRowKey.indexOf(record.id), 1);
        //     this.selectedRowKey.splice(
        //       this.selectedRowKey.indexOf(record.id),
        //       1
        //     );
        //   }
        //   console.log(this.selectedRows);
        //   console.log(this.selectedRowKey);
        //   //   this.selectedRowKey = selectedRowKey;
        //   //   console.log(this.selectedRows);
        //   //   console.log(this.selectedRowKey);
        // },
        getCheckboxProps: record => ({
          props: {
            disabled: this.selectedRowKey.indexOf(record.id) != -1,
          },
        }),
      }
    },
  },
}
</script>
<style scoped>
span {
  display: inline-block;
}
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 600px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.ant-advanced-search-form .ant-form-item {
  height: 80px;
  margin-bottom: 15px;
}
.ant-form-item {
  height: 50px;
  margin-bottom: 15px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.check-box-ro {
  background: #f3f7ff;
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 10px;
  margin-bottom: 6px;
  display: inline-block;
  font-size: 14px;
  align-items: center;
}
::v-deep .ant-popover-inner-content {
  padding: 5;
}
::v-deep .ant-form-item-label {
  text-align: right;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
.ant-calendar-picker-input.ant-input {
  border-radius: 0;
}
</style>
